// using module.exports here so gatsby-config can read it

process.env.builderAPIKey = process.env.builderAPIKey || 'ea0c489c583b478ca4835af553742fec';

process.env.ENABLE_GATSBY_REFRESH_ENDPOINT=1

module.exports = {
  // TODO: uncomment next line and enter your own public API key
  builderAPIKey: process.env.builderAPIKey
};
