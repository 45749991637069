import React, { useState, useEffect } from 'react';
import { Builder } from '@builder.io/react';

export const SelloxImage = (props) => {
    return (<img src={props.image} style={{ width: '100%' }} />)
}

Builder.registerComponent(
    SelloxImage,
    {
        name: "Sellox Image",
        inputs: [
            {
                name: "image",
                type: "string"
            },
            {
                name: "language",
                type: "string",
                defaultValue: "javascript",
            },
        ],
    }
);

