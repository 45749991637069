import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { BuilderComponent } from '@builder.io/react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '../components/Link/Link';
import '../builder-settings';
import theme from '../theme';


import '../components/slider';
import '../components/image';
import { CartDialog } from '../components/cart-dialog/cart-dialog';
import { AppLoader } from '../components/loader';

const query = graphql`
  query {
    allBuilderModels {
      header(limit: 1, options: { cachebust: true }) {
        content
      }
      footer(limit: 1, options: { cachebust: true }) {
        content
      }
    }
  }
`;

function PageLayout({ children }) {
  return (
    <StaticQuery query={query}>
      {data => {
        const models = data.allBuilderModels;
        const header = models.header[0].content;
        const footer = models.footer[0].content;
        return (
          <div>
            <div>
              <BuilderComponent
                renderLink={Link}
                name="header"
                content={header}
              />
            </div>
            <div className="routes">{children}</div>

            <CartDialog></CartDialog>
            <AppLoader></AppLoader>
            <div>
              <BuilderComponent
                renderLink={Link}
                name="footer"
                content={footer}
              />
            </div>
          </div>
        );
      }}
    </StaticQuery>
  );
}

export default PageLayout;
