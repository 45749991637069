
import dynamic from 'next/dynamic';
import {
  BuilderComponent,
  withChildren,
  Builder,
  BuilderBlocks
} from '@builder.io/react';




Builder.registerComponent(
  dynamic(() => import('./app-slider').then(mod => mod.AppSlider as any)),
  {
    name: "Sellox Slider",
    inputs: [
      {
        name: "isOpen",
        type: "boolean",
      },
      {
        name: "data",
        type: "list"
      }
    ],
  }
);