import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import './cart-dialog.scss';
import { Subject } from 'rxjs';

import { Cart } from '@sellox/sellox-sdk';

const subject = new Subject();

/**
 *
 */
export const adapter = {
    $: subject.asObservable(),
    notify: val => subject.next(val)
};

export const CartDialog = props => {
    const [state, setState] = useState({ opened: false });

    useEffect(() => {
        const sub = adapter.$.subscribe(data => {
            setState({ opened: !!data });
        });

        return () => {
            sub?.unsubscribe();
        };
    }, []);

    const close = e => {
        setState({ opened: false });
    };
    return (
        <div className="cart-dialog-wrapper">
            <div
                className={`scrim ${state.opened ? 'opened' : ''}`}
                onClick={close}
            />
            <div className={`cart-dialog pad ${state.opened ? 'opened' : ''}`}>
                <h1 className="title">Awesome! Just added to your cart 🎉</h1>
                <div
                    className="layout horizontal actions center-center"
                    style={{ height: '100px' }}
                >
                    <Link
                        to={`/cart`}
                        onClick={e => {
                            setState({ opened: false });
                        }}
                    >
                        <span>View Cart</span>
                        <span className="badge">{Cart.quantity}</span>
                    </Link>

                    <Link
                        to={`/`}
                        onClick={e => setState({ opened: false })}
                    >
                        <span>Keep Shopping</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};
