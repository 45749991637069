import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Bootstrap,
  Auth,
  IUser,
  ICart,
  EMPTY_CART,
  Cart,
  Shop
} from '@sellox/sellox-sdk';
import { filter } from 'rxjs/operators';

import './app.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../theme';
import { environment } from '../environment';


export default function RootLayout(props) {

  const [state, setState] = useState({});

  useEffect(() => {

  }, [])


  return (
    <React.Fragment>
      <Helmet>
        {/* <meta charset="utf-8" /> */}
        <meta name="generator" content="PWA Starter Kit" />
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1, initial-scale=1, user-scalable=yes"
        />
        <title>E&P Group - Home</title>
        <meta
          name="description"
          content="supporting the undertakings of domestic and international businesses in enhancing their market access, linkages, operations and sustainability in the Gambia."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="E&P Group - Gambia" />
        <meta property="og:description" content="" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&display=swap" rel="stylesheet" />

        {/* <meta
          property="og:image"
          content="http://cdn.shopify.com/s/files/1/1385/7027/t/7/assets/slide_6_1024x1024.jpg?1050789499310414205"
        />
        <meta
          property="og:image:secure_url"
          content="https://cdn.shopify.com/s/files/1/1385/7027/t/7/assets/slide_6_1024x1024.jpg?1050789499310414205"
        /> */}
        {/* <meta property="og:url" content="https://reminiscebyro.com/" />
        <meta property="og:site_name" content="Reminisce by Ro Cosmetics " /> */}

        <meta name="twitter:card" content="summary" />
        {/* <meta name="twitter:site" content="@reminiscebyro" />
        <meta name="twitter:title" content="Reminisce by Ro Cosmetics" /> */}
        <meta name="twitter:description" content="" />
        {/* <meta
          name="twitter:image"
          content="https://cdn.shopify.com/s/files/1/1385/7027/t/7/assets/slide_1_1024x1024.jpg?1050789499310414205"
        /> */}
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </React.Fragment>
  );
}
