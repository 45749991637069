import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import './loader.scss';
import { Subject } from 'rxjs';


const subject = new Subject();

/**
 *
 */
export const adapter = {
    $: subject.asObservable(),
    notify: val => subject.next(val)
};


export const AppLoader = props => {

    const [state, setState] = useState({ opened: false });

    useEffect(() => {
        const sub = adapter.$.subscribe(data => {
            setState({ opened: !!data });
        });

        return () => {
            sub?.unsubscribe();
        };
    }, []);

    const close = e => {
        setState({ opened: false });
    };

    return (
        <div className="app-loader" hidden={!state.opened}>
            <div className="layout horizontal center container">
                <div className="content">
                    <div className="loader-circle" />
                    <div className="loader-line-mask">
                        <div className="loader-line" />
                    </div>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="144px" height="144px" viewBox="0 0 144 144" enableBackground="new 0 0 144 144" xmlSpace="preserve">
                        <g>
                            <g>
                                <g>
                                    <path fill="#0586da" d="M61.352,73.978L44.308,91.021c1.172,1.64,2.486,3.214,3.957,4.687c0.005,0.006,0.008,0.01,0.014,0.013
				c0.005,0.007,0.011,0.01,0.014,0.017c1.477,1.472,3.047,2.784,4.687,3.954l22.605-22.605c-1.146-0.309-2.345-0.488-3.585-0.488
				C68.158,76.598,64.538,75.646,61.352,73.978z" />
                                    <path fill="#0586da" d="M49.785,59.53L37.564,71.751c0.264,4.74,1.438,9.438,3.529,13.813l15.191-15.192
				C53.199,67.474,50.909,63.739,49.785,59.53z" />
                                    <path fill="#0586da" d="M81.197,80.143l-22.763,22.764c4.296,2.054,8.908,3.22,13.564,3.509c0.084,0.005,0.168,0.014,0.252,0.02
				l2.525-2.524l10.739-10.738c0.185-0.899,0.279-1.827,0.279-2.777C85.795,86.322,84.013,82.671,81.197,80.143z" />
                                </g>
                                <g>
                                    <path fill="#0586da" d="M82.646,70.026l17.658-17.66c2.393-2.393,2.393-6.277,0-8.67c-2.395-2.396-6.277-2.396-8.669,0
				L68.416,66.913c1.146,0.309,2.342,0.488,3.583,0.488C75.839,67.401,79.459,68.354,82.646,70.026z" />
                                    <path fill="#0586da" d="M104.639,74.045c2.396-2.395,2.396-6.279,0-8.67c-2.395-2.396-6.275-2.396-8.673,0l-8.254,8.254
				c3.089,2.896,5.378,6.633,6.502,10.842L104.639,74.045L104.639,74.045z" />
                                    <path fill="#0586da" d="M78.628,39.358c-1.795-1.79-4.418-2.239-6.629-1.351c-0.744,0.298-1.442,0.746-2.043,1.351l-0.731,0.732
				L58.483,50.829c-0.185,0.898-0.28,1.826-0.28,2.776c0,4.071,1.783,7.726,4.597,10.253l15.828-15.827
				C81.021,45.636,81.021,41.754,78.628,39.358z" />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    );
};